<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <UserNav activePage="admincompanies" activeTab="2"></UserNav>
                <!--begin::Content-->
                <div class="flex-row-fluid ml-lg-8">
                    <!--begin::Content-->
                    <div class="flex-row-fluid ">
                        <div class="row">
                            <div class="col-xl-12">
                                <ListWidget8 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListWidget8 from "@/view/content/widgets/admin/Widget8.vue"
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
    name: 'corporate',
    components: {
        ListWidget8,
        UserNav
    },
    data() {
        return {
            items: [
                { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                { age: 38, first_name: 'Jami', last_name: 'Carney' }
            ]
        }
    },
    mounted() {
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 13 });
    }
}
</script>
