<template>
    <!--begin::Card-->
    <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark fs-15">Companies</h3>
            <div class="card-toolbar">
                <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                    <li class="nav-item">
                        <export-excel class="btn btn-ingomugreen py-2 px-4 active mr-2 btn-sm" :data="corpList"
                            :fields="excel_fields" worksheet="Corporate Clients" name="IngomuCorporateList.xls">
                            Excel
                        </export-excel>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'corporatesignup' }"
                            class="nav-link btn-ingomublue py-2 px-4 active btn-sm">New
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
            <div class="mt-5 mb-5">
                <b-form-group class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search">
                        </b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <!--begin: Datatable-->
            <b-table id="coaches-table" :items="corpList" :fields="fields" :per-page="perPage"
                :current-page="currentPage" show-empty :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered" class="table table-borderless table-vertical-center">
                <template #cell(image)="data" class="pl-0 py-4">
                    <div class="symbol symbol-50 symbol-light mr-1">
                        <router-link :to="{ name: 'corporatedetail', params: { corpId: data.item.id } }"
                            class="text-dark text-hover-primary font-weight-bold font-size-lg mb-1">
                            <img :src="companyImageSource(data.item.corpimage)" class="mh-75px obcover br-42 align-self-center"
                                alt="" />
                        </router-link>
                    </div>
                </template>
                <template #cell(name)="data" class="pl-0 py-4">
                    <router-link :to="{ name: 'corporatedetail', params: { corpId: data.item.id } }"
                        class="text-dark text-hover-primary font-weight-bold font-size-lg mb-1">
                        {{ data.item.name }}
                    </router-link>
                </template>
                <template #cell(started)="data" class="pl-0 py-4">
                    {{ shortFormatDate(data.item.startdate) }}
                </template>
                <template #cell(totallicenses)="data" class="pl-0 py-4">
                    {{data.item.number_licenses}}
                </template>
                <template #cell(used)="data" class="pl-0 py-4">
                    {{data.item.total_used}}
                </template>
                <template #cell(online)="data" class="pl-0 py-4">
                    <span class="label label-lg label-light-success label-inline" v-if="data.item.online">Online</span>
                    <span class="label label-lg label-light-danger label-inline" v-else>Offline</span>
                </template>
                <template #cell(status)="data" class="pl-0 py-4">
                    <router-link :to="{ name: 'corporatedetail', params: { corpId: data.item.id } }" class="btn btn-icon btn-light btn-hover-primary btn-xs">
                        <i class="la la-arrow-right icon-xl text-ingomublue"></i>
                    </router-link>
                </template>
            </b-table>
            <!--end: Datatable-->
        </div>
        <!-- begin: footer -->
        <div class="card-footer">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
            </b-pagination>
        </div>
        <!-- end: footer -->
    </div>
    <!--end::Card-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'widget8',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    data() {
        return {
            excel_fields: {
                'ID': 'id',
                'Name': 'name',
                'Number of Licenses': 'number_licenses',
                'Total Active': 'total_active',
                'Total Used': 'total_used',
                'Online?': 'online',
                'Joined': {
                    field: 'startdate',
                    callback: (value) => {
                        let myDate = new Date(value.seconds * 1000);
                        return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()}`;
                    }
                },
            },
            perPage: 10,
            currentPage: 1,
            fields: [
                { key: "image", label: "" },
                { key: "name", label: "Name" },
                { key: "started", label: "Joined" },
                { key: "totallicenses", label: "Total" },
                { key: "used", label: "Used" },
                { key: "online", label: "" },
                { key: "status", label: "" }
            ],
            filter: null,
            filterOn: [],
        }
    },
    mounted() {
        this.$store.dispatch("getCorpList");
    },
    computed: {
        ...mapGetters(["corpList"]),
        rows() {
            return this.corpList.length;
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            // this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    }
}
</script>

<style scoped>
.mh-75px {
        max-height: 75px !important;
      }
</style>